import { Card } from "../types";

export default [
  {
    title: "Botswana",
    image: "/scenery/botswana_1.jpg",
  },
  {
    title: "Botswana",
    image: "/scenery/botswana_2.jpg",
  },
  {
    title: "Botswana",
    image: "/scenery/botswana_3.jpg",
  },
  {
    title: "Eswatini",
    image: "/scenery/eswatini_1.jpg",
  },
  {
    title: "Eswatini",
    image: "/scenery/eswatini_2.jpg",
  },
  {
    title: "Eswatini",
    image: "/scenery/eswatini_3.jpg",
  },
  {
    title: "Ghana",
    image: "/scenery/ghana_1.jpg",
  },
  {
    title: "Ghana",
    image: "/scenery/ghana_2.jpg",
  },
  {
    title: "Ghana",
    image: "/scenery/ghana_3.jpg",
  },
  {
    title: "Kenya",
    image: "/scenery/kenya_1.jpg",
  },
  {
    title: "Kenya",
    image: "/scenery/kenya_2.jpg",
  },
  {
    title: "Kenya",
    image: "/scenery/kenya_3.jpg",
  },
  {
    title: "Lesotho",
    image: "/scenery/lesotho_1.jpg",
  },
  {
    title: "Lesotho",
    image: "/scenery/lesotho_2.jpg",
  },
  {
    title: "Lesotho",
    image: "/scenery/lesotho_3.jpg",
  },
  {
    title: "Madagascar",
    image: "/scenery/madagascar_1.jpg",
  },
  {
    title: "Madagascar",
    image: "/scenery/madagascar_2.jpg",
  },
  {
    title: "Madagascar",
    image: "/scenery/madagascar_3.jpg",
  },
  {
    title: "Nigeria",
    image: "/scenery/nigeria_1.jpg",
  },
  {
    title: "Nigeria",
    image: "/scenery/nigeria_2.jpg",
  },
  {
    title: "Nigeria",
    image: "/scenery/nigeria_3.jpg",
  },
  {
    title: "Senegal",
    image: "/scenery/senegal_1.jpg",
  },
  {
    title: "Senegal",
    image: "/scenery/senegal_2.jpg",
  },
  {
    title: "Senegal",
    image: "/scenery/senegal_3.jpg",
  },
  {
    title: "South Africa",
    image: "/scenery/south_africa_1.jpg",
  },
  {
    title: "South Africa",
    image: "/scenery/south_africa_2.jpg",
  },
  {
    title: "South Africa",
    image: "/scenery/south_africa_3.jpg",
  },
  {
    title: "Tunisia",
    image: "/scenery/tunisia_1.jpg",
  },
  {
    title: "Tunisia",
    image: "/scenery/tunisia_2.jpg",
  },
  {
    title: "Tunisia",
    image: "/scenery/tunisia_3.jpg",
  },
  {
    title: "Uganda",
    image: "/scenery/uganda_1.jpg",
  },
  {
    title: "Uganda",
    image: "/scenery/uganda_2.jpg",
  },
  {
    title: "Uganda",
    image: "/scenery/uganda_3.jpg",
  },
] as Card[];
