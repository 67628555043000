import { Card } from "../types";

export default [
  {
    title: "Albania",
    image: "/scenery/albania_1.jpg",
  },
  {
    title: "Albania",
    image: "/scenery/albania_2.jpg",
  },
  {
    title: "Albania",
    image: "/scenery/albania_3.jpg",
  },
  {
    title: "Andorra",
    image: "/scenery/andorra_1.jpg",
  },
  {
    title: "Andorra",
    image: "/scenery/andorra_2.jpg",
  },
  {
    title: "Andorra",
    image: "/scenery/andorra_3.jpg",
  },
  {
    title: "Austria",
    image: "/scenery/austria_1.jpg",
  },
  {
    title: "Austria",
    image: "/scenery/austria_2.jpg",
  },
  {
    title: "Austria",
    image: "/scenery/austria_3.jpg",
  },
  {
    title: "Belgium",
    image: "/scenery/belgium_1.jpg",
  },
  {
    title: "Belgium",
    image: "/scenery/belgium_2.jpg",
  },
  {
    title: "Belgium",
    image: "/scenery/belgium_3.jpg",
  },
  {
    title: "Bulgaria",
    image: "/scenery/bulgaria_1.jpg",
  },
  {
    title: "Bulgaria",
    image: "/scenery/bulgaria_2.jpg",
  },
  {
    title: "Bulgaria",
    image: "/scenery/bulgaria_3.jpg",
  },
  {
    title: "Croatia",
    image: "/scenery/croatia_1.jpg",
  },
  {
    title: "Croatia",
    image: "/scenery/croatia_2.jpg",
  },
  {
    title: "Croatia",
    image: "/scenery/croatia_3.jpg",
  },
  {
    title: "Czech Republic",
    image: "/scenery/czech_republic_1.jpg",
  },
  {
    title: "Czech Republic",
    image: "/scenery/czech_republic_2.jpg",
  },
  {
    title: "Czech Republic",
    image: "/scenery/czech_republic_3.jpg",
  },
  {
    title: "Denmark",
    image: "/scenery/denmark_1.jpg",
  },
  {
    title: "Denmark",
    image: "/scenery/denmark_2.jpg",
  },
  {
    title: "Denmark",
    image: "/scenery/denmark_3.jpg",
  },
  {
    title: "Estonia",
    image: "/scenery/estonia_1.jpg",
  },
  {
    title: "Estonia",
    image: "/scenery/estonia_2.jpg",
  },
  {
    title: "Estonia",
    image: "/scenery/estonia_3.jpg",
  },
  {
    title: "Faroe Islands",
    image: "/scenery/faroe_islands_1.jpg",
  },
  {
    title: "Faroe Islands",
    image: "/scenery/faroe_islands_2.jpg",
  },
  {
    title: "Faroe Islands",
    image: "/scenery/faroe_islands_3.jpg",
  },
  {
    title: "Finland",
    image: "/scenery/finland_1.jpg",
  },
  {
    title: "Finland",
    image: "/scenery/finland_2.jpg",
  },
  {
    title: "Finland",
    image: "/scenery/finland_3.jpg",
  },
  {
    title: "France",
    image: "/scenery/france_1.jpg",
  },
  {
    title: "France",
    image: "/scenery/france_2.jpg",
  },
  {
    title: "France",
    image: "/scenery/france_3.jpg",
  },
  {
    title: "Germany",
    image: "/scenery/germany_1.jpg",
  },
  {
    title: "Germany",
    image: "/scenery/germany_2.jpg",
  },
  {
    title: "Germany",
    image: "/scenery/germany_3.jpg",
  },
  {
    title: "Gibraltar",
    image: "/scenery/gibraltar_1.jpg",
  },
  {
    title: "Gibraltar",
    image: "/scenery/gibraltar_2.jpg",
  },
  {
    title: "Gibraltar",
    image: "/scenery/gibraltar_3.jpg",
  },
  {
    title: "Greece",
    image: "/scenery/greece_1.jpg",
  },
  {
    title: "Greece",
    image: "/scenery/greece_2.jpg",
  },
  {
    title: "Greece",
    image: "/scenery/greece_3.jpg",
  },
  {
    title: "Hungary",
    image: "/scenery/hungary_1.jpg",
  },
  {
    title: "Hungary",
    image: "/scenery/hungary_2.jpg",
  },
  {
    title: "Hungary",
    image: "/scenery/hungary_3.jpg",
  },
  {
    title: "Iceland",
    image: "/scenery/iceland_1.jpg",
  },
  {
    title: "Iceland",
    image: "/scenery/iceland_2.jpg",
  },
  {
    title: "Iceland",
    image: "/scenery/iceland_3.jpg",
  },
  {
    title: "Ireland",
    image: "/scenery/ireland_1.jpg",
  },
  {
    title: "Ireland",
    image: "/scenery/ireland_2.jpg",
  },
  {
    title: "Ireland",
    image: "/scenery/ireland_3.jpg",
  },
  {
    title: "Isle of Man",
    image: "/scenery/isle_of_man_1.jpg",
  },
  {
    title: "Isle of Man",
    image: "/scenery/isle_of_man_2.jpg",
  },
  {
    title: "Isle of Man",
    image: "/scenery/isle_of_man_3.jpg",
  },
  {
    title: "Italy",
    image: "/scenery/italy_1.jpg",
  },
  {
    title: "Italy",
    image: "/scenery/italy_2.jpg",
  },
  {
    title: "Italy",
    image: "/scenery/italy_3.jpg",
  },
  {
    title: "Jersey",
    image: "/scenery/jersey_1.jpg",
  },
  {
    title: "Jersey",
    image: "/scenery/jersey_2.jpg",
  },
  {
    title: "Jersey",
    image: "/scenery/jersey_3.jpg",
  },
  {
    title: "Latvia",
    image: "/scenery/latvia_1.jpg",
  },
  {
    title: "Latvia",
    image: "/scenery/latvia_2.jpg",
  },
  {
    title: "Latvia",
    image: "/scenery/latvia_3.jpg",
  },
  {
    title: "Lithuania",
    image: "/scenery/lithuania_1.jpg",
  },
  {
    title: "Lithuania",
    image: "/scenery/lithuania_2.jpg",
  },
  {
    title: "Lithuania",
    image: "/scenery/lithuania_3.jpg",
  },
  {
    title: "Luxembourg",
    image: "/scenery/luxembourg_1.jpg",
  },
  {
    title: "Luxembourg",
    image: "/scenery/luxembourg_2.jpg",
  },
  {
    title: "Luxembourg",
    image: "/scenery/luxembourg_3.jpg",
  },
  {
    title: "Malta",
    image: "/scenery/malta_1.jpg",
  },
  {
    title: "Malta",
    image: "/scenery/malta_2.jpg",
  },
  {
    title: "Malta",
    image: "/scenery/malta_3.jpg",
  },
  {
    title: "Monaco",
    image: "/scenery/monaco_1.jpg",
  },
  {
    title: "Monaco",
    image: "/scenery/monaco_2.jpg",
  },
  {
    title: "Monaco",
    image: "/scenery/monaco_3.jpg",
  },
  {
    title: "Montenegro",
    image: "/scenery/montenegro_1.jpg",
  },
  {
    title: "Montenegro",
    image: "/scenery/montenegro_2.jpg",
  },
  {
    title: "Montenegro",
    image: "/scenery/montenegro_3.jpg",
  },
  {
    title: "Netherlands",
    image: "/scenery/netherlands_1.jpg",
  },
  {
    title: "Netherlands",
    image: "/scenery/netherlands_2.jpg",
  },
  {
    title: "Netherlands",
    image: "/scenery/netherlands_3.jpg",
  },
  {
    title: "North Macedonia",
    image: "/scenery/north_macedonia_1.jpg",
  },
  {
    title: "North Macedonia",
    image: "/scenery/north_macedonia_2.jpg",
  },
  {
    title: "North Macedonia",
    image: "/scenery/north_macedonia_3.jpg",
  },
  {
    title: "Norway",
    image: "/scenery/norway_1.jpg",
  },
  {
    title: "Norway",
    image: "/scenery/norway_2.jpg",
  },
  {
    title: "Norway",
    image: "/scenery/norway_3.jpg",
  },
  {
    title: "Poland",
    image: "/scenery/poland_1.jpg",
  },
  {
    title: "Poland",
    image: "/scenery/poland_2.jpg",
  },
  {
    title: "Poland",
    image: "/scenery/poland_3.jpg",
  },
  {
    title: "Portugal",
    image: "/scenery/portugal_1.jpg",
  },
  {
    title: "Portugal",
    image: "/scenery/portugal_2.jpg",
  },
  {
    title: "Portugal",
    image: "/scenery/portugal_3.jpg",
  },
  {
    title: "Romania",
    image: "/scenery/romania_1.jpg",
  },
  {
    title: "Romania",
    image: "/scenery/romania_2.jpg",
  },
  {
    title: "Romania",
    image: "/scenery/romania_3.jpg",
  },
  {
    title: "San Marino",
    image: "/scenery/san_marino_1.jpg",
  },
  {
    title: "San Marino",
    image: "/scenery/san_marino_2.jpg",
  },
  {
    title: "San Marino",
    image: "/scenery/san_marino_3.jpg",
  },
  {
    title: "Serbia",
    image: "/scenery/serbia_1.jpg",
  },
  {
    title: "Serbia",
    image: "/scenery/serbia_2.jpg",
  },
  {
    title: "Serbia",
    image: "/scenery/serbia_3.jpg",
  },
  {
    title: "Slovakia",
    image: "/scenery/slovakia_1.jpg",
  },
  {
    title: "Slovakia",
    image: "/scenery/slovakia_2.jpg",
  },
  {
    title: "Slovakia",
    image: "/scenery/slovakia_3.jpg",
  },
  {
    title: "Slovenia",
    image: "/scenery/slovenia_1.jpg",
  },
  {
    title: "Slovenia",
    image: "/scenery/slovenia_2.jpg",
  },
  {
    title: "Slovenia",
    image: "/scenery/slovenia_3.jpg",
  },
  {
    title: "Spain",
    image: "/scenery/spain_1.jpg",
  },
  {
    title: "Spain",
    image: "/scenery/spain_2.jpg",
  },
  {
    title: "Spain",
    image: "/scenery/spain_3.jpg",
  },
  {
    title: "Sweden",
    image: "/scenery/sweden_1.jpg",
  },
  {
    title: "Sweden",
    image: "/scenery/sweden_2.jpg",
  },
  {
    title: "Sweden",
    image: "/scenery/sweden_3.jpg",
  },
  {
    title: "Switzerland",
    image: "/scenery/switzerland_1.jpg",
  },
  {
    title: "Switzerland",
    image: "/scenery/switzerland_2.jpg",
  },
  {
    title: "Switzerland",
    image: "/scenery/switzerland_3.jpg",
  },
  {
    title: "Turkey",
    image: "/scenery/turkey_1.jpg",
  },
  {
    title: "Turkey",
    image: "/scenery/turkey_2.jpg",
  },
  {
    title: "Turkey",
    image: "/scenery/turkey_3.jpg",
  },
  {
    title: "Ukraine",
    image: "/scenery/ukraine_1.jpg",
  },
  {
    title: "Ukraine",
    image: "/scenery/ukraine_2.jpg",
  },
  {
    title: "Ukraine",
    image: "/scenery/ukraine_3.jpg",
  },
  {
    title: "United Kingdom",
    image: "/scenery/uk_1.jpg",
  },
  {
    title: "United Kingdom",
    image: "/scenery/uk_2.jpg",
  },
  {
    title: "United Kingdom",
    image: "/scenery/uk_3.jpg",
  },
] as Card[];
