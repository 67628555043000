import { Card } from "../types";

export default [
  {
    title: "Botswana",
    image: "/bollards/bollard_1.jpg",
  },
  {
    title: "Botswana",
    image: "/bollards/bollard_3.jpg",
  },
  {
    title: "Ghana",
    image: "/bollards/bollard_4.jpg",
  },
  {
    title: "Ghana",
    image: "/bollards/bollard_5.jpg",
  },
  {
    title: "Ghana",
    image: "/bollards/bollard_6.jpg",
  },
  {
    title: "Lesotho",
    image: "/bollards/bollard_9.jpg",
  },
  {
    title: "Lesotho",
    image: "/bollards/bollard_10.jpg",
  },
  {
    title: "Lesotho",
    image: "/bollards/bollard_11.jpg",
  },
  {
    title: "Nigeria",
    image: "/bollards/bollard_12.jpg",
  },
  {
    title: "Senegal",
    image: "/bollards/bollard_14.jpg",
  },
  {
    title: "Tunisia",
    image: "/bollards/bollard_17.jpg",
  },
  {
    title: "Eswatini",
    image: "/bollards/bollard_240.jpg",
  },
  {
    title: "South Africa",
    image: "/bollards/bollard_274.jpg",
  },
  {
    title: "Tunisia",
    image: "/bollards/bollard_310.jpg",
  },
  {
    title: "Tunisia",
    image: "/bollards/bollard_311.jpg",
  },
  {
    title: "Madagascar",
    image: "/bollards/bollard_312.jpg",
  },
  {
    title: "Botswana",
    image: "/bollards/bollard_2.jpg",
  },
  {
    title: "Ghana",
    image: "/bollards/bollard_7.jpg",
  },
  {
    title: "Kenya",
    image: "/bollards/bollard_8.jpg",
  },
  {
    title: "Senegal",
    image: "/bollards/bollard_13.jpg",
  },
  {
    title: "Senegal",
    image: "/bollards/bollard_15.jpg",
  },
  {
    title: "South Africa",
    image: "/bollards/bollard_237.jpg",
  },
  {
    title: "Tunisia",
    image: "/bollards/bollard_306.jpg",
  },
  {
    title: "Kenya",
    image: "/bollards/bollard_320.jpg",
  },
  {
    title: "Senegal",
    image: "/bollards/bollard_16.jpg",
  },
] as Card[];
