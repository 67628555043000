import { Card } from "../types";

export default [
  {
    title: "Canada",
    image: "/scenery/canada_1.jpg",
  },
  {
    title: "Canada",
    image: "/scenery/canada_2.jpg",
  },
  {
    title: "Canada",
    image: "/scenery/canada_3.jpg",
  },
  {
    title: "Greenland",
    image: "/scenery/greenland_1.jpg",
  },
  {
    title: "Greenland",
    image: "/scenery/greenland_2.jpg",
  },
  {
    title: "Greenland",
    image: "/scenery/greenland_3.jpg",
  },
  {
    title: "Guatemala",
    image: "/scenery/guatemala_1.jpg",
  },
  {
    title: "Guatemala",
    image: "/scenery/guatemala_2.jpg",
  },
  {
    title: "Guatemala",
    image: "/scenery/guatemala_3.jpg",
  },
  {
    title: "Mexico",
    image: "/scenery/mexico_1.jpg",
  },
  {
    title: "Mexico",
    image: "/scenery/mexico_2.jpg",
  },
  {
    title: "Mexico",
    image: "/scenery/mexico_3.jpg",
  },
  {
    title: "United States",
    image: "/scenery/us_1.jpg",
  },
  {
    title: "United States",
    image: "/scenery/us_2.jpg",
  },
  {
    title: "United States",
    image: "/scenery/us_3.jpg",
  },
  {
    title: "US Virgin Islands",
    image: "/scenery/US_virgin_islands_1.jpg",
  },
  {
    title: "US Virgin Islands",
    image: "/scenery/US_virgin_islands_2.jpg",
  },
  {
    title: "US Virgin Islands",
    image: "/scenery/US_virgin_islands_3.jpg",
  },
] as Card[];
