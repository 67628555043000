import { Card } from "../types";

export default [
  {
    title: "Bulgaria",
    image: "/bollards/bollard_111.jpg",
  },
  {
    title: "France",
    image: "/bollards/bollard_124.jpg",
  },
  {
    title: "Portugal",
    image: "/bollards/bollard_156.jpg",
  },
  {
    title: "Romania",
    image: "/bollards/bollard_159.jpg",
  },
  {
    title: "Romania",
    image: "/bollards/bollard_161.jpg",
  },

  {
    title: "Luxembourg",
    image: "/bollards/bollard_258.jpg",
  },
  {
    title: "South Africa",
    image: "/bollards/bollard_274.jpg",
  },
  {
    title: "Luxembourg",
    image: "/bollards/bollard_287.jpg",
  },
  {
    title: "Estonia",
    image: "/bollards/bollard_304.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_308.jpg",
  },
  {
    title: "France",
    image: "/bollards/bollard_121.jpg",
  },
  {
    title: "France",
    image: "/bollards/bollard_125.jpg",
  },
  {
    title: "Monaco",
    image: "/bollards/bollard_142.jpg",
  },
  {
    title: "Netherlands",
    image: "/bollards/bollard_147.jpg",
  },
  {
    title: "North Macedonia",
    image: "/bollards/bollard_151.jpg",
  },
  {
    title: "Poland",
    image: "/bollards/bollard_154.jpg",
  },
  {
    title: "Romania",
    image: "/bollards/bollard_160.jpg",
  },
  {
    title: "Turkey",
    image: "/bollards/bollard_176.jpg",
  },
  {
    title: "Northern Ireland (UK)",
    image: "/bollards/bollard_236.jpg",
  },
  {
    title: "England (UK)",
    image: "/bollards/bollard_256.jpg",
  },
  {
    title: "Albania",
    image: "/bollards/bollard_245.jpg",
  },
  {
    title: "Croatia",
    image: "/bollards/bollard_265.jpg",
  },
  {
    title: "United Kingdom",
    image: "/bollards/bollard_267.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_294.jpg",
  },
  {
    title: "Netherlands",
    image: "/bollards/bollard_295.jpg",
  },
  {
    title: "Belgium",
    image: "/bollards/bollard_105.jpg",
  },
  {
    title: "Belgium",
    image: "/bollards/bollard_106.jpg",
  },
  {
    title: "Belgium",
    image: "/bollards/bollard_108.jpg",
  },
  {
    title: "Denmark",
    image: "/bollards/bollard_115.jpg",
  },
  {
    title: "Iceland",
    image: "/bollards/bollard_129.jpg",
  },
  {
    title: "Belgium",
    image: "/bollards/bollard_325.jpg",
  },
  {
    title: "Faroe Islands",
    image: "/bollards/bollard_118.jpg",
  },
  {
    title: "Albania",
    image: "/bollards/bollard_97.jpg",
  },
  {
    title: "Albania",
    image: "/bollards/bollard_98.jpg",
  },
  {
    title: "Austria",
    image: "/bollards/bollard_103.jpg",
  },
  {
    title: "Austria",
    image: "/bollards/bollard_104.jpg",
  },
  {
    title: "Belgium",
    image: "/bollards/bollard_107.jpg",
  },
  {
    title: "Bulgaria",
    image: "/bollards/bollard_109.jpg",
  },
  {
    title: "Croatia",
    image: "/bollards/bollard_112.jpg",
  },
  {
    title: "Czech Republic",
    image: "/bollards/bollard_113.jpg",
  },
  {
    title: "Czech Republic",
    image: "/bollards/bollard_114.jpg",
  },
  {
    title: "Greece",
    image: "/bollards/bollard_127.jpg",
  },
  {
    title: "Hungary",
    image: "/bollards/bollard_128.jpg",
  },
  {
    title: "Isle of Man",
    image: "/bollards/bollard_133.jpg",
  },
  {
    title: "Italy",
    image: "/bollards/bollard_134.jpg",
  },
  {
    title: "Italy",
    image: "/bollards/bollard_135.jpg",
  },
  {
    title: "Jersey",
    image: "/bollards/bollard_136.jpg",
  },
  {
    title: "Lithuania",
    image: "/bollards/bollard_139.jpg",
  },
  {
    title: "Luxembourg",
    image: "/bollards/bollard_140.jpg",
  },
  {
    title: "Montenegro",
    image: "/bollards/bollard_143.jpg",
  },
  {
    title: "Montenegro",
    image: "/bollards/bollard_144.jpg",
  },
  {
    title: "Montenegro",
    image: "/bollards/bollard_145.jpg",
  },
  {
    title: "Netherlands",
    image: "/bollards/bollard_146.jpg",
  },
  {
    title: "Netherlands",
    image: "/bollards/bollard_148.jpg",
  },
  {
    title: "North Macedonia",
    image: "/bollards/bollard_149.jpg",
  },
  {
    title: "North Macedonia",
    image: "/bollards/bollard_150.jpg",
  },
  {
    title: "North Macedonia",
    image: "/bollards/bollard_152.jpg",
  },
  {
    title: "Portugal",
    image: "/bollards/bollard_157.jpg",
  },
  {
    title: "San Marino",
    image: "/bollards/bollard_162.jpg",
  },
  {
    title: "Serbia",
    image: "/bollards/bollard_163.jpg",
  },
  {
    title: "Serbia",
    image: "/bollards/bollard_164.jpg",
  },
  {
    title: "Serbia",
    image: "/bollards/bollard_165.jpg",
  },
  {
    title: "Slovakia",
    image: "/bollards/bollard_166.jpg",
  },
  {
    title: "Slovakia",
    image: "/bollards/bollard_167.jpg",
  },
  {
    title: "Slovenia",
    image: "/bollards/bollard_168.jpg",
  },
  {
    title: "Ukraine",
    image: "/bollards/bollard_178.jpg",
  },
  {
    title: "Ukraine",
    image: "/bollards/bollard_179.jpg",
  },
  {
    title: "United Kingdom",
    image: "/bollards/bollard_181.jpg",
  },
  {
    title: "Czech Republic",
    image: "/bollards/bollard_242.jpg",
  },
  {
    title: "Austria",
    image: "/bollards/bollard_262.jpg",
  },
  {
    title: "Sweden",
    image: "/bollards/bollard_280.jpg",
  },
  {
    title: "Andorra",
    image: "/bollards/bollard_282.jpg",
  },
  {
    title: "Austria",
    image: "/bollards/bollard_284.jpg",
  },
  {
    title: "Slovenia",
    image: "/bollards/bollard_313.jpg",
  },
  {
    title: "Albania",
    image: "/bollards/bollard_323.jpg",
  },
  {
    title: "Israel",
    image: "/bollards/bollard_43.jpg",
  },
  {
    title: "Israel",
    image: "/bollards/bollard_46.jpg",
  },
  {
    title: "Estonia",
    image: "/bollards/bollard_116.jpg",
  },
  {
    title: "Finland",
    image: "/bollards/bollard_119.jpg",
  },
  {
    title: "Germany",
    image: "/bollards/bollard_126.jpg",
  },
  {
    title: "Latvia",
    image: "/bollards/bollard_138.jpg",
  },
  {
    title: "Luxembourg",
    image: "/bollards/bollard_141.jpg",
  },
  {
    title: "Norway",
    image: "/bollards/bollard_153.jpg",
  },
  {
    title: "Portugal",
    image: "/bollards/bollard_155.jpg",
  },
  {
    title: "Portugal",
    image: "/bollards/bollard_158.jpg",
  },
  {
    title: "Sweden",
    image: "/bollards/bollard_171.jpg",
  },
  {
    title: "Switzerland",
    image: "/bollards/bollard_172.jpg",
  },
  {
    title: "Switzerland",
    image: "/bollards/bollard_173.jpg",
  },
  {
    title: "Switzerland",
    image: "/bollards/bollard_174.jpg",
  },
  {
    title: "Ukraine",
    image: "/bollards/bollard_180.jpg",
  },
  {
    title: "Sweden",
    image: "/bollards/bollard_187.jpg",
  },
  {
    title: "Lithuania",
    image: "/bollards/bollard_269.jpg",
  },
  {
    title: "Austria",
    image: "/bollards/bollard_276.jpg",
  },
  {
    title: "Sweden",
    image: "/bollards/bollard_299.jpg",
  },
  {
    title: "France",
    image: "/bollards/bollard_302.jpg",
  },
  {
    title: "Slovakia",
    image: "/bollards/bollard_266.jpg",
  },
  {
    title: "Estonia",
    image: "/bollards/bollard_117.jpg",
  },
  {
    title: "Latvia",
    image: "/bollards/bollard_137.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_170.jpg",
  },
  {
    title: "Åland (Finland)",
    image: "/bollards/bollard_235.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_260.jpg",
  },
  {
    title: "Sweden",
    image: "/bollards/bollard_298.jpg",
  },
  {
    title: "Israel",
    image: "/bollards/bollard_44.jpg",
  },
  {
    title: "Malaysia",
    image: "/bollards/bollard_62.jpg",
  },
  {
    title: "Bulgaria",
    image: "/bollards/bollard_110.jpg",
  },
  {
    title: "France",
    image: "/bollards/bollard_120.jpg",
  },
  {
    title: "France",
    image: "/bollards/bollard_122.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_169.jpg",
  },
  {
    title: "Romania",
    image: "/bollards/bollard_175.jpg",
  },
  {
    title: "Andorra",
    image: "/bollards/bollard_99.jpg",
  },
  {
    title: "Ireland",
    image: "/bollards/bollard_130.jpg",
  },
  {
    title: "Ireland",
    image: "/bollards/bollard_131.jpg",
  },
  {
    title: "Ireland",
    image: "/bollards/bollard_132.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_324.jpg",
  },
  {
    title: "Czech Republic",
    image: "/bollards/bollard_123.jpg",
  },
  {
    title: "Hungary",
    image: "/bollards/bollard_316.jpg",
  },
  {
    title: "Luxembourg",
    image: "/bollards/bollard_327.jpg",
  },
  {
    title: "Andorra",
    image: "/bollards/bollard_100.jpg",
  },
  {
    title: "Andorra",
    image: "/bollards/bollard_101.jpg",
  },
  {
    title: "Andorra",
    image: "/bollards/bollard_102.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_255.jpg",
  },
  {
    title: "United Kingdom",
    image: "/bollards/bollard_257.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_263.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_268.jpg",
  },
  {
    title: "Norway",
    image: "/bollards/bollard_270.jpg",
  },
  {
    title: "Finland",
    image: "/bollards/bollard_271.jpg",
  },
  {
    title: "Sweden",
    image: "/bollards/bollard_272.jpg",
  },
  {
    title: "Sweden",
    image: "/bollards/bollard_273.jpg",
  },
  {
    title: "United Kingdom",
    image: "/bollards/bollard_278.jpg",
  },
  {
    title: "Italy",
    image: "/bollards/bollard_279.jpg",
  },
  {
    title: "Norway",
    image: "/bollards/bollard_288.jpg",
  },
  {
    title: "Norway",
    image: "/bollards/bollard_289.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_296.jpg",
  },
  {
    title: "Slovenia",
    image: "/bollards/bollard_297.jpg",
  },
  {
    title: "Greece",
    image: "/bollards/bollard_300.jpg",
  },
  {
    title: "France",
    image: "/bollards/bollard_307.jpg",
  },
  {
    title: "Spain",
    image: "/bollards/bollard_309.jpg",
  },
  {
    title: "Denmark",
    image: "/bollards/bollard_314.jpg",
  },
  {
    title: "Denmark",
    image: "/bollards/bollard_315.jpg",
  },
  {
    title: "Andorra",
    image: "/bollards/bollard_326.jpg",
  },
] as Card[];
