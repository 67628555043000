import { Card } from "../types";

export default [
  {
    title: "Australia",
    image: "/bollards/bollard_214.jpg",
  },
  {
    title: "Australia",
    image: "/bollards/bollard_215.jpg",
  },
  {
    title: "Australia",
    image: "/bollards/bollard_217.jpg",
  },
  {
    title: "New Zealand",
    image: "/bollards/bollard_218.jpg",
  },
  {
    title: "Christmas Island",
    image: "/bollards/bollard_233.jpg",
  },
  {
    title: "Christmas Island",
    image: "/bollards/bollard_234.jpg",
  },
  {
    title: "New Zealand",
    image: "/bollards/bollard_301.jpg",
  },
  {
    title: "Australia",
    image: "/bollards/bollard_293.jpg",
  },
  {
    title: "Australia",
    image: "/bollards/bollard_216.jpg",
  },
  {
    title: "Australia",
    image: "/bollards/bollard_275.jpg",
  },
] as Card[];
