import { Card } from "../types";

export default [
  {
    title: "Christmas Islands",
    image: "/scenery/christmas_islands_1.jpg",
  },
  {
    title: "Christmas Islands",
    image: "/scenery/christmas_islands_2.jpg",
  },
  {
    title: "Christmas Islands",
    image: "/scenery/christmas_islands_3.jpg",
  },
  {
    title: "Australia",
    image: "/scenery/australia_1.jpg",
  },
  {
    title: "Australia",
    image: "/scenery/australia_2.jpg",
  },
  {
    title: "Australia",
    image: "/scenery/australia_3.jpg",
  },
  {
    title: "Guam",
    image: "/scenery/guam_1.jpg",
  },
  {
    title: "New Zealand",
    image: "/scenery/new_zealand_1.jpg",
  },
  {
    title: "New Zealand",
    image: "/scenery/new_zealand_2.jpg",
  },
  {
    title: "New Zealand",
    image: "/scenery/new_zealand_3.jpg",
  },
  {
    title: "Northern Mariana Islands",
    image: "/scenery/northern_mariana_islands_1.jpg",
  },
  {
    title: "Northern Mariana Islands",
    image: "/scenery/northern_mariana_islands_2.jpg",
  },
  {
    title: "Northern Mariana Islands",
    image: "/scenery/northern_mariana_islands_3.jpg",
  },
] as Card[];
