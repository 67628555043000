import "swiped-events";
import { Card } from "./types";

export interface Color {
  r: number;
  g: number;
  b: number;
}

const GColor = (r?: number, g?: number, b?: number): Color => {
  r = typeof r === "undefined" ? 0 : r;
  g = typeof g === "undefined" ? 0 : g;
  b = typeof b === "undefined" ? 0 : b;
  return { r: r, g: g, b: b };
};

const createColorRange = (from: Color, to: Color, steps: number) => {
  const stepR = (to.r - from.r) / steps;
  const stepG = (to.g - from.g) / steps;
  const stepB = (to.b - from.b) / steps;
  const colors: Color[] = [];
  for (let i = 0; i < steps; i++) {
    colors.push(
      GColor(from.r + stepR * i, from.g + stepG * i, from.b + stepB * i)
    );
  }
  return colors;
};

export const renderFlashcards = (cards: Card[]) => {
  const VISIBLE_CARD_COUNT = 10;
  const shuffleArray = (array: Card[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  let currentCard = 0;

  /*set own values*/
  const CARD_PEN_OFFSET = 2, //displacement of the cards
    CARD_SWITCH_RANGE = "120%";

  const createCard = (card: Card, color: Color) => {
    const element = document.createElement("div");
    element.classList.add("card");
    element.innerHTML = `<img src="${card.image}" alt="${card.title}" loading="lazy" /><p class="hidden">${card.title}</p>`;
    return element;
  };

  const colorRange = createColorRange(
    GColor(255, 141, 0),
    GColor(0, 255, 231),
    cards.length
  );

  const CARD_ARRAY = shuffleArray(cards).map((card, i) => {
    const element = createCard(card, GColor(0, 0, 0));
    element.style.backgroundColor = `rgb(${colorRange[i].r}, ${colorRange[i].g}, ${colorRange[i].b})`;
    // document.body.appendChild(element);
    return element;
  });

  /* Do not change this */
  const COUNT_OF_CARDS = CARD_ARRAY.length;
  let last_element = CARD_ARRAY[CARD_ARRAY.length - 1];
  let isMoving = false;

  let offsetArray = [],
    offset = 0,
    l = CARD_ARRAY.length;

  for (let i = 1; i <= l; i++) {
    offsetArray.push(offset);
    offset += CARD_PEN_OFFSET;
  }

  setCardOffset();
  function setCardOffset() {
    CARD_ARRAY.forEach(function (item, index) {
      item.style.zIndex = `${Math.abs(index - COUNT_OF_CARDS)}`;
      item.style.transform = `translate(${offsetArray[index]}px, ${offsetArray[index]}px)`;
    });
  }

  /******************************************************************/
  window.addEventListener("wheel", function (e) {
    if (e.deltaY < 0) {
      cardSwitching("backward");
    } else {
      cardSwitching("forward");
    }
  });
  window.addEventListener("keydown", function (e) {
    if (e.keyCode === 37) {
      cardSwitching("backward");
    } else if (e.keyCode === 39) {
      cardSwitching("forward");
    }
  });

  document.addEventListener("swiped-left", function (e) {
    cardSwitching("backward");
  });

  document.addEventListener("swiped-right", function (e) {
    cardSwitching("forward");
  });

  window.addEventListener("click", () => {
    if (isMoving) return;
    const card = CARD_ARRAY[currentCard];
    const p = card.querySelector("p");
    p.classList.toggle("hidden");
  });

  function cardSwitching(direction: "forward" | "backward") {
    let animationObject: HTMLDivElement,
      previousSibling,
      scrolling = "";

    /* return when you scroll during the animation of a card */
    if (isMoving) return;

    // if (e.keyCode !== 38 && e.keyCode !== 40 && e.keyCode !== undefined) return;

    for (let index of CARD_ARRAY) {
      if (
        parseInt(window.getComputedStyle(index).zIndex) === CARD_ARRAY.length ||
        parseInt(index.style.zIndex) === CARD_ARRAY.length
      ) {
        /*switch the rearmost card */
        if (direction === "backward") {
          // decrement currentCard, looping around to the last card
          currentCard =
            currentCard === 0 ? CARD_ARRAY.length - 1 : currentCard - 1;

          //deltaY < 0 -> scrolling up
          previousSibling = index.previousElementSibling as HTMLDivElement;
          if (previousSibling === null) {
            previousSibling = last_element;
          }
        } else {
          currentCard =
            currentCard === CARD_ARRAY.length - 1 ? 0 : currentCard + 1;
          // document.body.appendChild(CARD_ARRAY[currentCard + VISIBLE_CARD_COUNT]);
        }

        animationObject =
          direction === "backward"
            ? previousSibling
            : direction === "forward"
            ? index
            : "";
        animationObject.style.transform = `translate(0px, -${CARD_SWITCH_RANGE})`;
        scrolling =
          direction === "backward"
            ? "up"
            : direction === "forward"
            ? "down"
            : "";
        isMoving = true;
      }
    }

    if (animationObject !== undefined) {
      animationObject.addEventListener(
        "transitionend",
        function () {
          if (scrolling === "down") {
            animationObject.style.zIndex = `${0}`;
            animationObject.style.transform = `translate(${offsetArray[COUNT_OF_CARDS]}px, ${offsetArray[COUNT_OF_CARDS]}px)`;
            offsetSwitch(scrolling);
          } else if (scrolling === "up") {
            offsetSwitch(scrolling);
            animationObject.style.zIndex = `${COUNT_OF_CARDS}`;
            animationObject.style.transform = `translate(0px, 0px)`;
          }
          scrolling = "";
        },
        { once: true }
      );
    }
  }

  function offsetSwitch(scrolling) {
    for (let index of CARD_ARRAY) {
      index.style.zIndex = `${
        scrolling === "down"
          ? parseInt(index.style.zIndex) + 1
          : parseInt(index.style.zIndex) - 1
      }`;
      let offsetIndex = Math.abs(parseInt(index.style.zIndex) - COUNT_OF_CARDS);
      index.style.transform = `translate(${offsetArray[offsetIndex]}px, ${offsetArray[offsetIndex]}px)`;

      index.addEventListener("transitionend", () => (isMoving = false), {
        once: true,
      });
    }
  }

  return {
    render: () => {
      return CARD_ARRAY.map((card) => card);
    },
  };
};
