import BollardsAfrica from "./data/bollards-africa";
import BollardsAsia from "./data/bollards-asia";
import BollardsEurope from "./data/bollards-europe";
import BollardsNA from "./data/bollards-na";
import BollardsSA from "./data/bollards-sa";
import BollardsAustralia from "./data/bollards-au";
import ScenerySA from "./data/scenery-sa";
import SceneryEU from "./data/scenery-eu";
import SceneryAF from "./data/scenery-af";
import SceneryNA from "./data/scenery-na";
import SceneryAU from "./data/scenery-au";
import SceneryAS from "./data/scenery-as";

import { router, Routes } from "./router";
import { renderFlashcards } from "./flashcards";
import { curry, FC, flush, render } from "./lib";

const route = window.location.pathname.split("/").join("/");

const store = {};

export const useElement = (id: string) => document.getElementById(id);

const registerLinks = () => {
  [...document.getElementsByTagName("a")].forEach((a) => {
    a.addEventListener("click", (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      const linkUrl = new URL(target.href);
      e.preventDefault();
      e.stopImmediatePropagation();
      window.history.pushState(null, null, linkUrl.pathname);
      router(routes, linkUrl.pathname);
    });
  });
};

const navigation = ({
  top,
  title,
  goBack,
  children,
}: {
  top?: boolean;
  title: string;
  goBack?: string;
  children?: string[];
}) => {
  return `
  <div class="navigation ${top ? "top" : ""}">
    <span>
      ${render(`<a href="${goBack}">&lsaquo;-</a>`, goBack)}
      ${title}
    </span>
    <span>
      ${render(curry(...children), children)}
    </span>
  </div>
`;
};

const bollardLinks = [
  `<a href="/bollards/af">af</a>`,
  `<a href="/bollards/as">as</a>`,
  `<a href="/bollards/sa">sa</a>`,
  `<a href="/bollards/na">na</a>`,
  `<a href="/bollards/eu">eu</a>`,
  `<a href="/bollards/au">au</a>`,
];

const sceneryLinks = [
  `<a href="/scenery/af">af</a>`,
  `<a href="/scenery/as">as</a>`,
  `<a href="/scenery/sa">sa</a>`,
  `<a href="/scenery/na">na</a>`,
  `<a href="/scenery/eu">eu</a>`,
  `<a href="/scenery/au">au</a>`,
];

const Home = () => {
  return navigation({
    title: "elk's google maps trainer",
    children: [
      `<a href="/bollards">bollards</a>`,
      `<a href="/scenery">scenery</a>`,
    ],
  });
};

const routes: Routes = {
  "/": () => {
    flush();
    document.body.innerHTML = render(Home, true);
    registerLinks();
  },
  "/bollards": () => {
    flush();
    const BollardsPage = navigation({
      goBack: "/",
      title: "bollards",
      children: bollardLinks,
    });
    document.body.innerHTML = BollardsPage;
    registerLinks();
  },
  "/bollards/*": (deck) => {
    flush();
    const bollardMaps = {
      as: BollardsAsia,
      af: BollardsAfrica,
      sa: BollardsSA,
      na: BollardsNA,
      eu: BollardsEurope,
      au: BollardsAustralia,
    };
    if (!bollardMaps[deck]) {
      console.error("Unknown deck", deck);
      return;
    }
    const flashcards = renderFlashcards(bollardMaps[deck]);
    const cardsHTML = flashcards.render();

    const Navigation = navigation({
      top: true,
      goBack: "/",
      title: "bollards",
      children: bollardLinks,
    });

    const CardWrapper: FC = `<div id="cards" class="cards"></div>`;
    const BollardsPage = curry(Navigation, CardWrapper);
    document.body.innerHTML = render(BollardsPage, true);
    cardsHTML.forEach((card) => {
      document.getElementById("cards")?.appendChild(card);
    });
    registerLinks();
  },
  "/scenery": () => {
    flush();
    const BollardsPage = navigation({
      goBack: "/",
      title: "scenery",
      children: sceneryLinks,
    });
    document.body.innerHTML = BollardsPage;
    registerLinks();
  },
  "/scenery/*": (deck) => {
    flush();
    const sceneryMaps = {
      eu: SceneryEU,
      sa: ScenerySA,
      af: SceneryAF,
      au: SceneryAU,
      na: SceneryNA,
      as: SceneryAS,
    };
    if (!sceneryMaps[deck]) {
      console.error("Unknown deck", deck);
      return;
    }
    const flashcards = renderFlashcards(sceneryMaps[deck]);
    const cardsHTML = flashcards.render();

    const Navigation = navigation({
      top: true,
      goBack: "/",
      title: "scenery",
      children: sceneryLinks,
    });

    const CardWrapper: FC = `<div id="cards" class="cards"></div>`;
    const BollardsPage = curry(Navigation, CardWrapper);
    document.body.innerHTML = render(BollardsPage, true);
    cardsHTML.forEach((card) => {
      document.getElementById("cards")?.appendChild(card);
    });
    registerLinks();
  },
};

router(routes, route);
