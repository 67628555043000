export type Renderable = string | (() => string) | HTMLElement;

function isNode(o: any) {
  return typeof Node === "object"
    ? o instanceof Node
    : o &&
        typeof o === "object" &&
        typeof o.nodeType === "number" &&
        typeof o.nodeName === "string";
}

//Returns true if it is a DOM element
function isElement(o: any) {
  return typeof HTMLElement === "object"
    ? o instanceof HTMLElement //DOM2
    : o &&
        typeof o === "object" &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === "string";
}

export type FC = Renderable;

export const flush = () => (document.body.innerHTML = "");
export const render = (html: Renderable, bool: any): string => {
  if (!Boolean(bool)) {
    console.log("RETURNING NOTHING");
    return "";
  }
  if (typeof html === "function") {
    return html();
  } else if (isNode(html) || isElement(html)) {
    return (html as HTMLElement).outerHTML;
  }
  return html.toString();
};

export const curry = (...components: Renderable[]): Renderable => {
  return components.reduce((acc, curr) => {
    return acc + render(curr, true);
  }, "");
};

// export const registerLinks = () => {
//   [...document.getElementsByTagName("a")].forEach((a) => {
//     a.addEventListener("click", (e: MouseEvent) => {
//       const target = e.target as HTMLAnchorElement;
//       const linkUrl = new URL(target.href);
//       e.preventDefault();
//       e.stopImmediatePropagation();
//       window.history.pushState(null, null, linkUrl.pathname);
//       // router(routes, linkUrl.pathname);
//     });
//   });
// };
