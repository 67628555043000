/**
 * Minimal route matcher with wildcard support
 * @param route route to match, i.e. /blog/*
 * @param path current path
 * @returns boolean indicating whether the route matches the path
 */
export const matchRoute = (route: string, path: string) => {
  const routeParts = route.split("/");
  const pathParts = path.split("/");
  if (routeParts.length !== pathParts.length) {
    return false;
  }
  for (let i = 0; i < routeParts.length; i++) {
    if (routeParts[i] === "*") {
      continue;
    }
    if (routeParts[i] !== pathParts[i]) {
      return false;
    }
  }
  return true;
};

export interface Routes {
  [key: string]: (...string) => void;
}

export const router = (routes: Routes, path: string) => {
  const routeKeys = Object.keys(routes);
  const routeKey = routeKeys.find((key) => matchRoute(key, path));
  const args = path
    .split("/")
    .filter((part, i) => routeKey.split("/")[i] === "*");

  const route = routeKey ? routes[routeKey] : () => {};
  route(...args);
};
