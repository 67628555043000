import { Card } from "../types";

export default [
  {
    title: "Bhutan",
    image: "/bollards/bollard_21.jpg",
  },
  {
    title: "Bhutan",
    image: "/bollards/bollard_24.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_26.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_33.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/bollards/bollard_57.jpg",
  },
  {
    title: "Malaysia",
    image: "/bollards/bollard_58.jpg",
  },
  {
    title: "Malaysia",
    image: "/bollards/bollard_61.jpg",
  },
  {
    title: "Mongolia",
    image: "/bollards/bollard_64.jpg",
  },
  {
    title: "Philippines",
    image: "/bollards/bollard_65.jpg",
  },
  {
    title: "Philippines",
    image: "/bollards/bollard_66.jpg",
  },
  {
    title: "Philippines",
    image: "/bollards/bollard_71.jpg",
  },
  {
    title: "Sri Lanka",
    image: "/bollards/bollard_85.jpg",
  },
  {
    title: "Sri Lanka",
    image: "/bollards/bollard_86.jpg",
  },
  {
    title: "Thailand",
    image: "/bollards/bollard_92.jpg",
  },
  {
    title: "Bangladesh",
    image: "/bollards/bollard_321.jpg",
  },
  {
    title: "Bangladesh",
    image: "/bollards/bollard_19.jpg",
  },
  {
    title: "Bangladesh",
    image: "/bollards/bollard_20.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_27.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_28.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_31.jpg",
  },
  {
    title: "Japan",
    image: "/bollards/bollard_47.jpg",
  },
  {
    title: "Malaysia",
    image: "/bollards/bollard_59.jpg",
  },
  {
    title: "Mongolia",
    image: "/bollards/bollard_63.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/bollards/bollard_253.jpg",
  },
  {
    title: "Malaysia",
    image: "/bollards/bollard_283.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_30.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_32.jpg",
  },
  {
    title: "Japan",
    image: "/bollards/bollard_50.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_82.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_322.jpg",
  },
  {
    title: "Japan",
    image: "/bollards/bollard_49.jpg",
  },
  {
    title: "Japan",
    image: "/bollards/bollard_51.jpg",
  },
  {
    title: "Jordan",
    image: "/bollards/bollard_52.jpg",
  },
  {
    title: "South Korea",
    image: "/bollards/bollard_77.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_39.jpg",
  },
  {
    title: "Malaysia",
    image: "/bollards/bollard_60.jpg",
  },
  {
    title: "Russia",
    image: "/bollards/bollard_72.jpg",
  },
  {
    title: "Russia",
    image: "/bollards/bollard_73.jpg",
  },
  {
    title: "Russia",
    image: "/bollards/bollard_75.jpg",
  },
  {
    title: "Russia",
    image: "/bollards/bollard_87.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_41.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_45.jpg",
  },
  {
    title: "Cambodia",
    image: "/bollards/bollard_29.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_34.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_40.jpg",
  },
  {
    title: "Israel",
    image: "/bollards/bollard_43.jpg",
  },
  {
    title: "Israel",
    image: "/bollards/bollard_46.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/bollards/bollard_53.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/bollards/bollard_55.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/bollards/bollard_56.jpg",
  },
  {
    title: "Laos",
    image: "/bollards/bollard_67.jpg",
  },
  {
    title: "Laos",
    image: "/bollards/bollard_68.jpg",
  },
  {
    title: "Laos",
    image: "/bollards/bollard_69.jpg",
  },
  {
    title: "Russia",
    image: "/bollards/bollard_74.jpg",
  },
  {
    title: "South Korea",
    image: "/bollards/bollard_79.jpg",
  },
  {
    title: "South Korea",
    image: "/bollards/bollard_80.jpg",
  },
  {
    title: "South Korea",
    image: "/bollards/bollard_84.jpg",
  },
  {
    title: "Thailand",
    image: "/bollards/bollard_91.jpg",
  },
  {
    title: "Thailand",
    image: "/bollards/bollard_93.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_38.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_42.jpg",
  },
  {
    title: "Japan",
    image: "/bollards/bollard_48.jpg",
  },
  {
    title: "Philippines",
    image: "/bollards/bollard_70.jpg",
  },
  {
    title: "Taiwan",
    image: "/bollards/bollard_88.jpg",
  },
  {
    title: "Taiwan",
    image: "/bollards/bollard_89.jpg",
  },
  {
    title: "Taiwan",
    image: "/bollards/bollard_90.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_254.jpg",
  },
  {
    title: "Thailand",
    image: "/bollards/bollard_94.jpg",
  },
  {
    title: "Thailand",
    image: "/bollards/bollard_95.jpg",
  },
  {
    title: "Japan",
    image: "/bollards/bollard_96.jpg",
  },
  {
    title: "Indonesia",
    image: "/bollards/bollard_252.jpg",
  },
  {
    title: "Israel",
    image: "/bollards/bollard_44.jpg",
  },
  {
    title: "Malaysia",
    image: "/bollards/bollard_62.jpg",
  },
  {
    title: "South Korea",
    image: "/bollards/bollard_78.jpg",
  },
  {
    title: "Bhutan",
    image: "/bollards/bollard_25.jpg",
  },
  {
    title: "Bhutan",
    image: "/bollards/bollard_22.jpg",
  },
  {
    title: "Bhutan",
    image: "/bollards/bollard_23.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/bollards/bollard_54.jpg",
  },
  {
    title: "Singapore",
    image: "/bollards/bollard_76.jpg",
  },
  {
    title: "South Korea",
    image: "/bollards/bollard_81.jpg",
  },
  {
    title: "South Korea",
    image: "/bollards/bollard_83.jpg",
  },
  {
    title: "Turkey",
    image: "/bollards/bollard_247.jpg",
  },
  {
    title: "Japan",
    image: "/bollards/bollard_292.jpg",
  },
] as Card[];
