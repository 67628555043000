import { Card } from "../types";

export default [
  {
    title: "Ecuador",
    image: "/bollards/bollard_246.jpg",
  },
  {
    title: "Peru",
    image: "/bollards/bollard_249.jpg",
  },
  {
    title: "Ecuador",
    image: "/bollards/bollard_221.jpg",
  },
  {
    title: "Ecuador",
    image: "/bollards/bollard_222.jpg",
  },
  {
    title: "Ecuador",
    image: "/bollards/bollard_223.jpg",
  },
  {
    title: "Ecuador",
    image: "/bollards/bollard_224.jpg",
  },
  {
    title: "Ecuador",
    image: "/bollards/bollard_225.jpg",
  },
  {
    title: "Ecuador",
    image: "/bollards/bollard_227.jpg",
  },
  {
    title: "Peru",
    image: "/bollards/bollard_229.jpg",
  },
  {
    title: "Peru",
    image: "/bollards/bollard_230.jpg",
  },
  {
    title: "Uruguay",
    image: "/bollards/bollard_231.jpg",
  },
  {
    title: "Peru",
    image: "/bollards/bollard_318.jpg",
  },
  {
    title: "Ecuador",
    image: "/bollards/bollard_226.jpg",
  },
  {
    title: "Argentina",
    image: "/bollards/bollard_317.jpg",
  },
  {
    title: "Brazil",
    image: "/bollards/bollard_264.jpg",
  },
  {
    title: "Chile",
    image: "/bollards/bollard_219.jpg",
  },
  {
    title: "Peru",
    image: "/bollards/bollard_228.jpg",
  },
  {
    title: "Colombia",
    image: "/bollards/bollard_291.jpg",
  },
  {
    title: "Peru",
    image: "/bollards/bollard_319.jpg",
  },
  {
    title: "Argentina",
    image: "/bollards/bollard_243.jpg",
  },
] as Card[];
