import { Card } from "../types";

export default [
  {
    title: "United States",
    image: "/bollards/bollard_200.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_203.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_209.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_286.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_206.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_261.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_290.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_281.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_201.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_202.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_204.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_205.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_207.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_211.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_248.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_199.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_208.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_210.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_212.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_277.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_213.jpg",
  },
  {
    title: "United States",
    image: "/bollards/bollard_285.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_183.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_186.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_188.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_193.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_189.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_190.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_191.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_194.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_192.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_185.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_251.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_184.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_250.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_259.jpg",
  },
  {
    title: "Canada",
    image: "/bollards/bollard_182.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_244.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_196.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_197.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_198.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_244.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_196.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_197.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_198.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_244.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_196.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_197.jpg",
  },
  {
    title: "Mexico",
    image: "/bollards/bollard_198.jpg",
  },
  {
    title: "Greenland",
    image: "/bollards/bollard_195.jpg",
  },
] as Card[];
