import { Card } from "../types";

export default [
  {
    title: "Curaçao",
    image: "/scenery/curacao_1.jpg",
  },
  {
    title: "Curaçao",
    image: "/scenery/curacao_2.jpg",
  },
  {
    title: "Curaçao",
    image: "/scenery/curacao_3.jpg",
  },
  {
    title: "Dominican Republic",
    image: "/scenery/dominican_republic_1.jpg",
  },
  {
    title: "Dominican Republic",
    image: "/scenery/dominican_republic_2.jpg",
  },
  {
    title: "Dominican Republic",
    image: "/scenery/dominican_republic_3.jpg",
  },
  {
    title: "Greenland",
    image: "/scenery/greenland_1.jpg",
  },
  {
    title: "Greenland",
    image: "/scenery/greenland_2.jpg",
  },
  {
    title: "Greenland",
    image: "/scenery/greenland_3.jpg",
  },
  {
    title: "Guatemala",
    image: "/scenery/guatemala_1.jpg",
  },
  {
    title: "Guatemala",
    image: "/scenery/guatemala_2.jpg",
  },
  {
    title: "Guatemala",
    image: "/scenery/guatemala_3.jpg",
  },
  {
    title: "Mexico",
    image: "/scenery/mexico_1.jpg",
  },
  {
    title: "Mexico",
    image: "/scenery/mexico_2.jpg",
  },
  {
    title: "Mexico",
    image: "/scenery/mexico_3.jpg",
  },
  {
    title: "Puerto Rico",
    image: "/scenery/puerto_rico_1.jpg",
  },
  {
    title: "Puerto Rico",
    image: "/scenery/puerto_rico_2.jpg",
  },
  {
    title: "Puerto Rico",
    image: "/scenery/puerto_rico_3.jpg",
  },
  {
    title: "Argentina",
    image: "/scenery/argentina_1.jpg",
  },
  {
    title: "Argentina",
    image: "/scenery/argentina_2.jpg",
  },
  {
    title: "Argentina",
    image: "/scenery/argentina_3.jpg",
  },
  {
    title: "Bolivia",
    image: "/scenery/bolivia_1.jpg",
  },
  {
    title: "Bolivia",
    image: "/scenery/bolivia_2.jpg",
  },
  {
    title: "Bolivia",
    image: "/scenery/bolivia_3.jpg",
  },
  {
    title: "Brazil",
    image: "/scenery/brazil_1.jpg",
  },
  {
    title: "Brazil",
    image: "/scenery/brazil_2.jpg",
  },
  {
    title: "Brazil",
    image: "/scenery/brazil_3.jpg",
  },
  {
    title: "Chile",
    image: "/scenery/chile_1.jpg",
  },
  {
    title: "Chile",
    image: "/scenery/chile_2.jpg",
  },
  {
    title: "Chile",
    image: "/scenery/chile_3.jpg",
  },
  {
    title: "Colombia",
    image: "/scenery/colombia_1.jpg",
  },
  {
    title: "Colombia",
    image: "/scenery/colombia_2.jpg",
  },
  {
    title: "Colombia",
    image: "/scenery/colombia_3.jpg",
  },
  {
    title: "Ecuador",
    image: "/scenery/ecuador_1.jpg",
  },
  {
    title: "Ecuador",
    image: "/scenery/ecuador_2.jpg",
  },
  {
    title: "Ecuador",
    image: "/scenery/ecuador_3.jpg",
  },
  {
    title: "Peru",
    image: "/scenery/peru_1.jpg",
  },
  {
    title: "Peru",
    image: "/scenery/peru_2.jpg",
  },
  {
    title: "Peru",
    image: "/scenery/peru_3.jpg",
  },
  {
    title: "Uruguay",
    image: "/scenery/uruguay_1.jpg",
  },
  {
    title: "Uruguay",
    image: "/scenery/uruguay_2.jpg",
  },
  {
    title: "Uruguay",
    image: "/scenery/uruguay_3.jpg",
  },
] as Card[];
