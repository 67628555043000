import { Card } from "../types";

export default [
  {
    title: "Bangladesh",
    image: "/scenery/bangladesh_1.jpg",
  },
  {
    title: "Bangladesh",
    image: "/scenery/bangladesh_2.jpg",
  },
  {
    title: "Bangladesh",
    image: "/scenery/bangladesh_3.jpg",
  },
  {
    title: "Bhutan",
    image: "/scenery/bhutan_1.jpg",
  },
  {
    title: "Bhutan",
    image: "/scenery/bhutan_2.jpg",
  },
  {
    title: "Bhutan",
    image: "/scenery/bhutan_3.jpg",
  },
  {
    title: "Cambodia",
    image: "/scenery/cambodia_1.jpg",
  },
  {
    title: "Cambodia",
    image: "/scenery/cambodia_2.jpg",
  },
  {
    title: "Cambodia",
    image: "/scenery/cambodia_3.jpg",
  },
  {
    title: "India",
    image: "/scenery/india_1.jpg",
  },
  {
    title: "India",
    image: "/scenery/india_2.jpg",
  },
  {
    title: "India",
    image: "/scenery/india_3.jpg",
  },
  {
    title: "Indonesia",
    image: "/scenery/indonesia_1.jpg",
  },
  {
    title: "Indonesia",
    image: "/scenery/indonesia_2.jpg",
  },
  {
    title: "Indonesia",
    image: "/scenery/indonesia_3.jpg",
  },
  {
    title: "Israel",
    image: "/scenery/israel_1.jpg",
  },
  {
    title: "Israel",
    image: "/scenery/israel_2.jpg",
  },
  {
    title: "Israel",
    image: "/scenery/israel_3.jpg",
  },
  {
    title: "Japan",
    image: "/scenery/japan_1.jpg",
  },
  {
    title: "Japan",
    image: "/scenery/japan_2.jpg",
  },
  {
    title: "Japan",
    image: "/scenery/japan_3.jpg",
  },
  {
    title: "Jordan",
    image: "/scenery/jordan_1.jpg",
  },
  {
    title: "Jordan",
    image: "/scenery/jordan_2.jpg",
  },
  {
    title: "Jordan",
    image: "/scenery/jordan_3.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/scenery/kyrgyzstan_1.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/scenery/kyrgyzstan_2.jpg",
  },
  {
    title: "Kyrgyzstan",
    image: "/scenery/kyrgyzstan_3.jpg",
  },
  {
    title: "Laos",
    image: "/scenery/laos_1.jpg",
  },
  {
    title: "Laos",
    image: "/scenery/laos_2.jpg",
  },
  {
    title: "Laos",
    image: "/scenery/laos_3.jpg",
  },
  {
    title: "Malaysia",
    image: "/scenery/malaysia_1.jpg",
  },
  {
    title: "Malaysia",
    image: "/scenery/malaysia_2.jpg",
  },
  {
    title: "Malaysia",
    image: "/scenery/malaysia_3.jpg",
  },
  {
    title: "Mongolia",
    image: "/scenery/mongolia_1.jpg",
  },
  {
    title: "Mongolia",
    image: "/scenery/mongolia_2.jpg",
  },
  {
    title: "Mongolia",
    image: "/scenery/mongolia_3.jpg",
  },
  {
    title: "Philippines",
    image: "/scenery/philippines_1.jpg",
  },
  {
    title: "Philippines",
    image: "/scenery/philippines_2.jpg",
  },
  {
    title: "Philippines",
    image: "/scenery/philippines_3.jpg",
  },
  {
    title: "Russia",
    image: "/scenery/russia_1.jpg",
  },
  {
    title: "Russia",
    image: "/scenery/russia_2.jpg",
  },
  {
    title: "Russia",
    image: "/scenery/russia_3.jpg",
  },
  {
    title: "Singapore",
    image: "/scenery/singapore_1.jpg",
  },
  {
    title: "Singapore",
    image: "/scenery/singapore_2.jpg",
  },
  {
    title: "Singapore",
    image: "/scenery/singapore_3.jpg",
  },
  {
    title: "South Korea",
    image: "/scenery/south_korea_1.jpg",
  },
  {
    title: "South Korea",
    image: "/scenery/south_korea_2.jpg",
  },
  {
    title: "South Korea",
    image: "/scenery/south_korea_3.jpg",
  },
  {
    title: "Sri Lanka",
    image: "/scenery/sri_lanka_1.jpg",
  },
  {
    title: "Sri Lanka",
    image: "/scenery/sri_lanka_2.jpg",
  },
  {
    title: "Sri Lanka",
    image: "/scenery/sri_lanka_3.jpg",
  },
  {
    title: "Taiwan",
    image: "/scenery/taiwan_1.jpg",
  },
  {
    title: "Taiwan",
    image: "/scenery/taiwan_2.jpg",
  },
  {
    title: "Taiwan",
    image: "/scenery/taiwan_3.jpg",
  },
  {
    title: "Thailand",
    image: "/scenery/thailand_1.jpg",
  },
  {
    title: "Thailand",
    image: "/scenery/thailand_2.jpg",
  },
  {
    title: "Thailand",
    image: "/scenery/thailand_3.jpg",
  },
  {
    title: "United Arab Emirates",
    image: "/scenery/UAE_1.jpg",
  },
  {
    title: "United Arab Emirates",
    image: "/scenery/UAE_2.jpg",
  },
  {
    title: "United Arab Emirates",
    image: "/scenery/UAE_3.jpg",
  },
  {
    title: "Vietnam",
    image: "/scenery/vietnam_1.jpg",
  },
  {
    title: "Vietnam",
    image: "/scenery/vietnam_2.jpg",
  },
  {
    title: "Vietnam",
    image: "/scenery/vietnam_3.jpg",
  },
] as Card[];
